import { Item, ItemFilters } from './items.types';
import { SupportedLocale } from '../../../i18n/locales';
import { convertObjectToQueryParams } from '../../../utils/routes';
import { ApiCallConfig, apiCall } from '../../../utils/apiCall';
import { PaginationResponse } from '../pagination.types';

export const getItemBySlug = async ({
  slug,
  ...params
}: ApiCallConfig & { slug: string }) =>
  await apiCall({
    ...params,
    endpoint: `items/slugs/${slug}`,
    method: 'GET',
    reshaper: (res: Item) => res,
  });

export const getItemByID = async ({
  id,
  ...params
}: ApiCallConfig & { id: string | number }) =>
  await apiCall({
    ...params,
    endpoint: `items/${id}`,
    method: 'GET',
    reshaper: (res: Item) => res,
  });

export const getApprovedItemsSlug = async (params: ApiCallConfig = {}) =>
  apiCall({
    ...params,
    endpoint: `items/approved/slugs`,
    method: 'GET',
    reshaper: (data: { slug: string }[]) => data,
  });

export const getItems = async (
  filters: ItemFilters,
  language: SupportedLocale,
  page = 1,
  limit = 0
) => {
  const url = `items?${convertObjectToQueryParams({
    ...filters,
    page,
    limit,
  })}`;

  return await apiCall({
    endpoint: url,
    method: 'GET',
    reshaper: (res: PaginationResponse<Item>) => res,
    language,
  });
};

export const getItem = async (
  itemID: string,
  language: SupportedLocale,
  userID?: string
) =>
  await apiCall({
    endpoint: `items/${itemID}?userid=${userID}`,
    method: 'GET',
    reshaper: (res: Item) => res,
    language,
  });

export const toggleItemToFavorites = async (userID: string, itemID: string) =>
  await apiCall({
    endpoint: `marketplace/users/${userID}/products/favorites/${itemID}`,
    method: 'POST',
    reshaper: (res: { status: 'added' | 'removed' }) => res,
  });

export const getItemFromFavorites = async (userID: string, itemID: string) =>
  await apiCall({
    endpoint: `marketplace/users/${userID}/products/favorites/${itemID}`,
    method: 'GET',
    reshaper: (res: { favorite: boolean }) => res,
  });
