export const ITEM_FILTERS = {
  STATUS: 'status',
  INCLUDE_OUT_OF_STOCK: 'includeOutOfStock',
  IN_PREORDER: 'inPreorder',
  SELLER: 'seller',
  SELLER_NAME: 'sellerName',
  EXCLUDE: 'exclude',
  INCLUDE: 'include',
  SIZE: 'size',
  SEX: 'sex',
  SEARCH: 'search',
  CATEGORY: 'category',
  NOT_VERIFIED_ITEMS: 'notVerifiedItems',
  MIN_PRICE: 'minPrice',
  MAX_PRICE: 'maxPrice',
  ORDER: 'order',
  USER: 'userID',
  ONLY_FAVORITES: 'onlyFavorites',
  ONLY_SALES: 'onlySales',
  COLLECTION: 'collection',
  SELECTION: 'selection',
  INCLUDE_SLUGS: 'includeSlugs',
  INCLUDE_UNRELEASED_COLLECTIONS: 'unreleasedCollections',
  PAGE: 'page',
  LIMIT: 'limit',
};

export const ITEM_ORDER = {
  NEW: 'new',
  HIGHEST_PRICE: 'highest-price',
  MOST_RELEVANT: 'most-relevant',
  LOWEST_PRICE: 'lowest-price',
};
