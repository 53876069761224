import React from 'react';

import { cn } from 'revibe-ui';

type Props = {
  type?: 'error' | 'neutral' | 'dark';
  isUppercase?: boolean;
  className?: string;
  children: React.ReactNode;
};

export const Tag = ({
  type = 'neutral',
  children,
  isUppercase = false,
  className,
}: Props) => {
  let color = 'text-black';
  let border = 'border-black';
  let background = 'bg-gray-50';

  if (type === 'error') {
    color = 'text-red-700';
    border = 'border-red-700';
    background = 'bg-gray-50';
  }

  if (type === 'dark') {
    color = 'text-gray-50';
    border = 'border-gray-50';
    background = 'bg-black';
  }

  return (
    <div
      className={cn(
        'box-border h-fit w-fit rounded-xl border px-2 py-0.5 text-xs font-semibold',
        isUppercase && 'uppercase',
        color,
        border,
        background,
        className
      )}
    >
      {children}
    </div>
  );
};
