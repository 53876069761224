import React from 'react';

import { Toggle } from 'revibe-ui';
import { useTranslation } from 'i18n/hooks';

import { useAuth } from 'modules/auth/hooks/useAuth';
import { useToast } from 'modules/common/hooks';

import { MarketplaceAPI } from 'revibe-api';
import { HeartIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';
import { useDataLayer } from 'modules/tracking/hooks/useDataLayer';

type Props = {
  item: MarketplaceAPI.Item;
  className?: string;
  hasText?: boolean;
};

export const FavoriteItemButton = ({
  item,
  className,
  hasText = false,
}: Props) => {
  const { tu } = useTranslation('shop');
  const { userID, isLoggedIn, authModal } = useAuth();
  const { errorToast, successToast } = useToast();
  const dataLayer = useDataLayer();

  const { data: favoriteStatus, refetch } = useQuery(
    [`item-${item.id}-is-favorite`, userID],
    async () => {
      if (!userID) {
        return null;
      }

      const { data, error } = await MarketplaceAPI.getItemFromFavorites(
        userID,
        item.id
      );

      if (error) {
        throw new Error(error);
      }

      if (!data) {
        return null;
      }

      return data;
    },
    {
      enabled: userID !== null,
    }
  );

  const handleFavoriteClick = async () => {
    if (!isLoggedIn || !userID) {
      return authModal.open();
    }

    const { error, data } = await MarketplaceAPI.toggleItemToFavorites(
      userID,
      item.id
    );

    if (!data) {
      dataLayer.trackException(`Error adding item ${item.name} to favorites`);
      return errorToast(error);
    }

    refetch();

    if (data.status === 'added') {
      dataLayer.trackAddToWishList(item);
      return successToast('item-added-to-favorites');
    } else if (data.status === 'removed') {
      return successToast('item-removed-from-favorites');
    }
  };

  return (
    <Toggle
      className={className}
      pressed={favoriteStatus?.favorite}
      defaultPressed={favoriteStatus?.favorite}
      onPressedChange={handleFavoriteClick}
    >
      <HeartIcon className="h-6 w-6 fill-white" />
      {hasText && (
        <p className="text-xs uppercase hover:underline">
          {favoriteStatus?.favorite
            ? tu('remove-from-wishlist')
            : tu('add-to-wishlist')}
        </p>
      )}
    </Toggle>
  );
};
