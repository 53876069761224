import { SHOP_ROUTES } from 'routes';

import { convertObjectToQueryParams } from 'modules/common/utils';
import { ObjectType } from 'modules/common/utils/types';

export const buildItemsListingRoute = (
  sex?: string | null,
  category?: string | number | null,
  filters?: ObjectType<any>
) => {
  return category
    ? `${SHOP_ROUTES.ITEMS}/${sex || 'all'}/${category}${
        filters ? `?${convertObjectToQueryParams(filters || {})}` : ''
      }`
    : `${SHOP_ROUTES.ITEMS}/${sex || 'unisex'}${
        filters ? `?${convertObjectToQueryParams(filters || {})}` : ''
      }`;
};
