import React from 'react';

import { useTranslation } from 'i18n/hooks';
import { SpacedContainer } from 'revibe-ui';

import { getPercentageCutFromPrice } from 'modules/item/utils/itemPrice';
import { getIsItemInStock } from 'modules/item/utils/itemStock';
import { formatPrice } from 'modules/catalog/utils/formatPrice';

import { Tag } from 'ui/components/Tag';
import { MarketplaceAPI } from 'revibe-api';

type Props = {
  item: MarketplaceAPI.Item;
};

export const ItemPreviewTags = ({ item }: Props) => {
  const { tu } = useTranslation('shop');
  const itemIsInStock = getIsItemInStock(item.stock);
  const itemIsAvailable = itemIsInStock || item.available_for_preorder;
  const percentageCut = item.selling_price
    ? getPercentageCutFromPrice(item.price, item.selling_price)
    : null;

  return (
    <SpacedContainer spacing="small" className="absolute top-4 left-4">
      {percentageCut && percentageCut > 0 ? (
        <Tag>- {formatPrice(percentageCut, 0)} &#37;</Tag>
      ) : null}
      {item.available_for_preorder && <Tag>{tu('preorder')}</Tag>}
      {!itemIsAvailable && <Tag type="error">{tu('out-of-stock')}</Tag>}
      {item.collection && (
        <Tag className="opacity-0 transition-opacity group-hover:opacity-100">
          {item.collection.name}
        </Tag>
      )}
    </SpacedContainer>
  );
};
