import React from 'react';

import { useTranslation } from 'i18n/hooks';
import Clamp from 'react-multiline-clamp';

type Props = {
  children: React.ReactNode;
  lines?: number;
  showReadMore?: boolean;
};

export const ClampText = ({
  children,
  lines = 5,
  showReadMore = true,
}: Props) => {
  const { tu } = useTranslation();

  return (
    <Clamp
      lines={lines}
      withToggle
      maxLines={100}
      showMoreElement={({ toggle }: any) =>
        showReadMore ? (
          <p
            className="cursor-pointer text-sm text-black underline"
            onClick={toggle}
          >
            {tu('read-more', 'common')}
          </p>
        ) : null
      }
      showLessElement={({ toggle }: any) =>
        showReadMore ? (
          <p
            className="cursor-pointer text-sm text-black underline"
            onClick={toggle}
          >
            {tu('read-less', 'common')}
          </p>
        ) : null
      }
    >
      <p className="whitespace-pre-wrap text-black">{children}</p>
    </Clamp>
  );
};
