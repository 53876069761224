import React from 'react';

import { SHOP_ROUTES } from 'routes';
import Image from 'next/image';
import { getDynamicRouteUrl } from 'modules/common/utils';
import { ItemPrice } from 'modules/item/components/ItemPrice';
import { FavoriteItemButton } from 'modules/catalog/components/FavoriteItemButton';
import { useDataLayer } from 'modules/tracking/hooks/useDataLayer';

import { Link } from 'ui/components/Link';

import { ItemPreviewTags } from './ItemPreviewTags';
import { MarketplaceAPI } from 'revibe-api';
import { AspectRatio } from 'revibe-ui';

type Props = {
  item: MarketplaceAPI.Item;
  list?: string;
};

export const ItemPreview = ({ item, list = 'generic' }: Props) => {
  const dataLayer = useDataLayer();
  const itemPhotoSrc =
    item.photos[0]?.medium_link ||
    'https://res.cloudinary.com/revibe/image/upload/v1639411886/home/Frame_17_ocht0g.svg';
  const altItemPhotoSrc = item.photos[1]?.medium_link;

  return (
    <div
      className="relative cursor-pointer overflow-hidden"
      onClick={() => {
        dataLayer.trackSelectItem(list, list, item);
      }}
    >
      <Link
        hasDecoration={false}
        to={getDynamicRouteUrl(SHOP_ROUTES.ITEM_SLUG, {
          slug: item.slug,
        })}
      >
        <div className="group cursor-pointer bg-gray-100 hover:text-black">
          <AspectRatio ratio={3 / 4}>
            <Image
              src={itemPhotoSrc}
              alt={item.name}
              className="bg-[#f3f3f3] object-cover"
              fill
            />
            {altItemPhotoSrc && (
              <Image
                src={altItemPhotoSrc}
                alt={item.name}
                className="bg-[#f3f3f3] object-cover opacity-0 transition-opacity md:hover:opacity-100"
                fill
              />
            )}
          </AspectRatio>
          <div className="font-formula bg-gray-50 px-4 py-2 text-sm uppercase tracking-wider hover:text-black md:text-base lg:text-lg">
            <div className="flex flex-col justify-between lg:flex-row lg:justify-between lg:gap-1">
              <p className="overflow-hidden text-ellipsis whitespace-nowrap">
                {item.name}
              </p>
              <div className="lg:self-end">
                <ItemPrice
                  price={item.price}
                  sellingPrice={item.selling_price}
                />
              </div>
            </div>
            <p className="hidden text-gray-500 lg:inline">{item.seller.name}</p>
          </div>
          <ItemPreviewTags item={item} />
        </div>
      </Link>

      <FavoriteItemButton item={item} className="absolute top-3 right-3" />
    </div>
  );
};
