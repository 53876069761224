import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'i18n/hooks';

import { useAuth } from 'modules/auth/hooks/useAuth';
import { useToast } from 'modules/common/hooks';
import { ItemPreview } from 'modules/item/components/ItemPreview';
import { ITEM_FILTERS } from 'modules/item/utils/itemFilter';
import { buildItemsListingRoute } from 'modules/catalog/utils';

import { Link } from 'ui/components/Link';
import { MarketplaceAPI } from 'revibe-api';
import { Button } from 'revibe-ui';

type Props = {
  filters?: MarketplaceAPI.ItemFilters;
  label: React.ReactNode;
  hideSeeAll?: boolean;
  list?: string;
};

export const ItemsCarousel = ({
  label,
  filters = {},
  hideSeeAll = false,
  list = 'generic',
}: Props) => {
  const { tu, language } = useTranslation();

  const { errorToast } = useToast();
  const [hasMore, setHasMore] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const { userID } = useAuth();
  const userFilters = {
    ...filters,
    [ITEM_FILTERS.USER]: userID,
  };

  const fetchPage = useCallback(async () => {
    const { data, error } = await MarketplaceAPI.getItems(
      userFilters,
      language,
      1,
      8
    );
    if (data) {
      setData(data.res);
      setHasMore(data.currentPage < data.totalPages);
    } else {
      errorToast(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [label]);

  useEffect(() => {
    fetchPage();
  }, [fetchPage]);

  const seeAllUrl = buildItemsListingRoute(
    filters[ITEM_FILTERS.SEX],
    filters[ITEM_FILTERS.CATEGORY],
    filters
  );

  return (
    <div>
      {label && (
        <div className="flex w-full gap-4 px-4 pb-2">
          <p className="font-formula text-4xl text-black">{label}</p>
          {!hideSeeAll && hasMore && (
            <Link className="self-center" to={seeAllUrl}>
              <Button variant="subtle" size="sm">
                {tu('common.see-all')}
              </Button>
            </Link>
          )}
        </div>
      )}
      <div className="flex flex-nowrap justify-start gap-4 overflow-scroll">
        {data.map((item) => (
          <div
            key={item.id + label?.toString()}
            className="min-w-[256px] border border-black first:ml-4 last:mr-4 lg:min-w-[360px] xl:min-w-[512px]"
          >
            <ItemPreview item={item} list={list} />
          </div>
        ))}
      </div>
    </div>
  );
};
