import { MarketplaceAPI } from 'revibe-api';

export const getItemStock = (stock: MarketplaceAPI.ItemStock) =>
  Object.values(stock).reduce((totalStock, amount) => totalStock + amount, 0);

export const isItemInPreorder = (stock: MarketplaceAPI.ItemStock) => {
  let inPreorder = false;
  Object.values(stock).forEach((stockEntry) => {
    if (stockEntry === -1) {
      inPreorder = true;
    }
  });
  return inPreorder;
};

export const getIsItemInStock = (stock: MarketplaceAPI.ItemStock) =>
  getItemStock(stock) > 0 || isItemInPreorder(stock);
